import React from "react";
import { connect } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import {isBetween, isPastDay, MtoJDateFormat, MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const ScheduleCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal
}) => {
  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const getScheduleType = () => {
    let _type = "";
    const isEndedSchedule = isPastDay(item?.toDate),
      isCurrentSchedule = isBetween(new Date() ,item.fromDate, item.toDate),
      isCurrentWithNullToDateSchedule = item?.toDate == null && isPastDay(item?.fromDate),
      isFutureSchedule = !isPastDay(item.fromDate);
    if(isEndedSchedule) _type = "برنامه پایان یافته";
    else if(isCurrentSchedule || isCurrentWithNullToDateSchedule) _type = "برنامه جاری";
    else if(isFutureSchedule) _type = "برنامه آینده";
    return _type;
  }

  return(
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="provider">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.provider?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="fromDate">{dataListTitles[2]}</label>
        <span className="font-en mr-2">{getCellValue(MtoJDateFormat(item?.fromDate))}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="toDate">{dataListTitles[3]}</label>
        <span className="mr-2">{getCellValue(MtoJDateFormat(item?.toDate))}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="status">{dataListTitles[4]}</label>
        <span className="mr-2">{getScheduleType()}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="updateDate">{dataListTitles[5]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-end my-3">
        <div className="d-flex align-items-center">
          {
            findAccessInAccessList('PROVIDER_SCHEDULE_EDIT') &&
            <Tooltip alt={'اتمام برنامه'} placement='bottom' className="tooltip-pre-line">
               <span
                 onClick={() => !item?.toDate && handleShowModal('closeScheduleModal', item?.id)}
                 className={`d-flex r mx-2 font-20 cur-pointer ${!item?.toDate ? 'text-red-hover' : 'disabled text-gray3'}`}
               >
                <Icon name='close-square'/>
              </span>
            </Tooltip>
          }
          {
            findAccessInAccessList('PROVIDER_SCHEDULE_VIEW') &&
            <Tooltip alt={'ویرایش'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.SCHEDULE_EDIT.PATH, {id: item?.id}))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='pen-square'/>
              </span>
            </Tooltip>
          }
          {
            findAccessInAccessList('PROVIDER_SCHEDULE_VIEW') &&
            <Tooltip alt={'جزئیات'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.SCHEDULE_ADD_ITEM.PATH, {id: item?.id}))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='eye'/>
              </span>
            </Tooltip>
          }
          {/*<span*/}
          {/*  onClick={() => handleShowModal('deleteScheduleModal', item?.id)}*/}
          {/*  className="d-flex text-red-hover mr-2 font-20 cur-pointer"*/}
          {/*>*/}
          {/*  <Icon name='trash'/>*/}
          {/*</span>*/}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ScheduleCardsBoxItem);