import {SET_FULL_NAME} from "./userData";

export const SET_ID = 'SET_ID';
export const SET_VIDEO_CALL_OPEN_PIP = 'SET_VIDEO_CALL_OPEN_PIP';
export const SET_IS_IN_VIDEO_CALL = 'SET_IS_IN_VIDEO_CALL';

export const videoCallId = (id: number) => {
  return {
    type: SET_ID,
    payload: id,
  };
};

export const setVideoCallOpenPiP = (value: boolean) => ({
  type: SET_VIDEO_CALL_OPEN_PIP,
  payload: value,
});

export const setIsInVideoCall = (value: boolean) => ({
  type: SET_IS_IN_VIDEO_CALL,
  payload: value,
});