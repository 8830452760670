// src/redux/reducers/idReducer.js

import {SET_ID, SET_IS_IN_VIDEO_CALL, SET_VIDEO_CALL_OPEN_PIP} from "../../slice/videoCallId";

const initialState = {
  value: 0, // Initial state of the ID
  videoCallOpenPiP: false,
  isInVideoCall: false
};

const videoCallIdReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ID:
      return {
        ...state,
        value: action.payload,
      };
    case SET_VIDEO_CALL_OPEN_PIP:
      return {
        ...state,
        videoCallOpenPiP: action.payload,
      };
    case SET_IS_IN_VIDEO_CALL:
      return {
        ...state,
        isInVideoCall: action.payload,
      };
    default:
      return state;
  }
};

export default videoCallIdReducer;
