import React, {useEffect, useRef, useState} from "react";

import {Rnd} from "react-rnd";
import Icon from "../../../general/icon";
import {useWindowSize} from "../../../../../hook/useWindowSize";
type PiPProps = {
  PiP: HTMLVideoElement | null,
  name: string,
  leaveSession: any,
}
type Status = {
  micStatus: any,
  micStatusChange: any,
  camStatus: any,
  camStatusChange: any,
}

const VideoCallPiP = ({
  floatingContent,
  statusContent,
  openModal
}: any) => {

  const [width] = useWindowSize();
  const [showPiP, setShowPiP] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const leaveSession = () => {
    openModal();
    const video = videoRef.current;
    if (video) {
      video.srcObject = null;
      setShowPiP(false);
    }
  };

  useEffect(() => {
    const video = videoRef.current
    if (floatingContent.PiP && video) {
      video.srcObject = floatingContent.PiP;
      setShowPiP(true);
    } else if (floatingContent.PiP === null) {
      setShowPiP(false);
    }
  }, [floatingContent]);

  const containerStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    objectFit: 'cover', // This is valid since it's typed as CSSProperties
  };

  return (
    <>
      <Rnd
        className={`bg-black ${showPiP ? '' : 'd-none'}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: '20px',
          background: "#f0f0f0",
          boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
          zIndex: 300,
          overflow: "hidden",
          position: 'fixed',
        }}
        default={{
          x: 10, y: 10,
          width: 300, height: 225
        }}
        lockAspectRatio={true}
        maxWidth={500}
        maxHeight={400}
        minWidth={300}
        minHeight={225}
      >
        <div className='pip-overlay-top'>
          <span className="btn d-flex font-25 text-dark-emphasis rounded-circle me-0 me-md-2 mb-1 p-2"
              onClick={leaveSession}
          >
            <Icon name='arrow-diagonal'/>
          </span>
        </div>
        <video ref={videoRef} autoPlay style={containerStyle} disablePictureInPicture/>
        <div className='overlay-bottom'>
          {floatingContent.name}
        </div>
        <div className='rnd-toolbar d-flex flex-column'>
          <span className="btn bg-video-call-button-black d-flex font-25 rounded-circle me-0 me-md-2 mb-1 p-2"
                onClick={statusContent.camStatusChange}
          >
            <Icon name={`${statusContent.camStatus ? 'video' : 'close-video'}`}/>
          </span>
          <span className="btn bg-video-call-button-black d-flex font-25 rounded-circle me-0 me-md-2 mb-1 p-2"
                onClick={statusContent.micStatusChange}
          >
            <Icon name={`${statusContent.micStatus ? 'microphone' : 'microphone-mute'}`}/>
          </span>
          <span className="btn bg-video-call-button-red d-flex font-25 rounded-circle me-0 me-md-2 mb-1 p-2"
                onClick={() => leaveSession()}
          >
            <Icon name='phone'/>
          </span>
        </div>
      </Rnd>
    </>
  )
}

export default VideoCallPiP;
