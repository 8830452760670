import React, { ReactNode, useCallback, useState } from "react";
import ReactSelect, { FormatOptionLabelMeta, components } from "react-select";
import AsyncSelect from "react-select/async";
import Icon from "../icon";
import { Controller } from "react-hook-form";

type SelectFieldProps = {
  options: any;
  name: string;
  isSearchable?: boolean;
  placeholder: string;
  onChange?: (e: any) => void | undefined;
  noBorder?: boolean;
  width?: string;
  returnObjectValue?: boolean;
  optionLabel?: string;
  optionValue?: string;
  className?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  formatOptionLabel?:
    | ((
    data: string,
    formatOptionLabelMeta: FormatOptionLabelMeta<string>
  ) => ReactNode)
    | undefined;
  icon?: string;
  iconContent?: string;
  NoOptionsText?: string;
  error?: any;
  control?: any;
  register?: any;
  watch?: any;
  handleChangeInputSelect?: any;
  searchParams?: any;
  setSearchParams?: any;
  request?:any,
  asyncLoadOptions?: any;
  returnLabel?: boolean;
};

const SelectField = ({
 options, // always a array like this: [{label: 'Option 1', value: 'opt1'}, ...]
 name,
 isSearchable,
 placeholder,
 onChange,
 noBorder,
 width,
 // multipleOptionsMode,
 returnObjectValue, // if true it'll retrun {label: 'Option 1', value: 'opt1'} instead of opt1
 optionLabel,
 optionValue,
 className = "",
 isDisabled,
 isClearable,
 isMulti,
 formatOptionLabel, // for change option items component
 icon,
 iconContent,
 NoOptionsText,
 error,
 control,
 register,
 watch,
 searchParams,
 setSearchParams,
 returnLabel
}: SelectFieldProps) => {

  const [_value, setValue] = useState("");
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{NoOptionsText || "بدون گزینه انتخابی"}</span>
      </components.NoOptionsMessage>
    );
  };

  const handleChange = useCallback(
    (val: any, onFieldChange: any) => {
      let returnValue = {
        target: {
          name: name,
          value: isMulti
            ? val
            : returnObjectValue
              ? val
              : optionValue
                ? val?.[optionValue]
                : val?.value,
          type: "select",
        },
      };

      onChange && onChange(returnValue);
      onFieldChange(returnValue);
    },
    [name, onChange, optionValue, returnObjectValue]
  );

  const handleChangeWithLabel = useCallback(
    (val: any, onFieldChange: any) => {
      let returnValue = {
        target: {
          name: name,
          value: isMulti
            ? val
            : returnObjectValue
              ? val
              : optionValue
                ? val?.[optionValue]
                : {value: val?.value, label: val?.label},
          type: "select",
        },
      };

      onChange && onChange(returnValue);
      onFieldChange(returnValue);
    },
    [name, onChange, optionValue, returnObjectValue]
  );


  const getSelectValue = (value: string) => {


    let _optValue =
      options &&
      options?.find((item: any) =>
        optionValue ? item[optionValue] === value : item.value === value
      );
    // let _optValue = options && options.find(item => optionValue ? item[optionValue] === value : item.value === value)
    // if (options && options.length === 1) handleChange(options[0]);
    if (_optValue === undefined && (value === null || value === ""))
      _optValue = null;
    return _optValue;
  };



  const selectStyle = {
    control: (
      base: any,
      { isFocused, isDisabled }: { isFocused: boolean; isDisabled: boolean }
    ) => ({
      ...base,
      minWidth: width || 140,
      minHeight: 47,
      // boxShadow: "0px 5px 80px rgb(148 182 223 / 15%)",
      borderRadius: 12,
      textTransform: "capitalize",
      cursor: "pointer",
      fontWeight: "300",
      fontSize: "13px",
      fontFamily: 'Vazir',
      ...(isDisabled && { backgroundColor: "#E9ECEF" }),
      ...(iconContent && { paddingRight: "40px" }),
      ...(noBorder
        ? { border: "none" }
        : showError
          ? {
            borderColor: "#E53935",
            ":hover": {
              borderColor: "#E53935",
            },
          }
          : {
            borderColor: isFocused ? "#3290FF" : "#E0E0E0",
            ":hover": {
              borderColor: isFocused ? "#3290FF" : null,
            },
          }),
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#909090', // Add your desired placeholder color here
      fontSize: "13px", // Adjust the font size if needed
      fontWeight: "300", // Adjust the font weight if needed
      opacity: 1, // Ensure full opacity
      fontFamily: 'Vazir'
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: "0 0 0 19px",
      color: "#9292a5",
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 2,
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 8,
      fontFamily: 'Vazir'
    }),
    option: (
      base: any,
      { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }
    ) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#ebf4ff" : null,
        color: isSelected ? "#3290FF" : null,
        fontWeight: isSelected ? "500" : null,
        textAlign: "right",
        borderRadius: 6,
        textTransform: "capitalize",
        cursor: "pointer",
      };
    },
    multiValue: (styles: any) => ({
      ...styles,
      padding: "5px 8px 4px",
      backgroundColor: "#e8eef1",
      borderRadius: "4px",
      fontSize: "14px",
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      borderRadius: "3px",
      transition: "color .2s ease-in",
      ":hover": {
        color: "#F0506D",
      },
    }),
  };

  const multipleOptionsProps = {
    getOptionLabel: (label: any) =>
      optionLabel ? label[optionLabel] : label.label,
    getOptionValue: (value: any) =>
      optionValue ? value[optionValue] : value.value,
  };

  const loadOptions = async (inputValue: any, callback: any) => {
    if (!inputValue) {
      return callback([]);
    }

    try {
      // Replace with your API endpoint
      const response = await fetch(`https://api.example.com/search?query=${inputValue}`);
      const data = await response.json();

      // Assuming your API returns an array of objects with "label" and "value"
      const options = data.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));

      callback(options);
    } catch (error) {
      console.error('Error fetching options:', error);
      callback([]);
    }
  };

  let showError = error?.[name] && error?.[name]?.message;

  return (
    <>
      <div
        className={`inp-box ${className} ${showError ? "invalid-field" : ""}`}
      >
        {iconContent ? (
          iconContent
        ) : (
          <span className="inp-icon">
            <Icon name={icon} />
          </span>
        )}
        {watch ? (
          <Controller
            control={control}
            name={name}
            defaultValue={null}
            {...register}
            render={({ field: { onChange, value } }) => (
              <ReactSelect
                options={options}
                value={getSelectValue(watch)}
                onChange={(e) => returnLabel ? handleChangeWithLabel(e, onChange) : handleChange(e, onChange)}
                styles={selectStyle}
                menuPlacement="auto"
                placeholder={placeholder ? placeholder : false}
                isSearchable={isSearchable}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isMulti={isMulti}
                formatOptionLabel={formatOptionLabel}
                {...multipleOptionsProps}
                components={{
                  IndicatorSeparator: () => null,
                  NoOptionsMessage,
                }}
              />
            )}
          />
        ) : control ? (
          <Controller
            control={control}
            name={name}
            defaultValue={null}
            {...register}
            render={({ field: { onChange, value } }) => (
              <ReactSelect
                options={options}
                value={getSelectValue(watch)}
                onChange={(e) => handleChange(e, onChange)}
                // onInputChange={handleChangeInputSelect}
                styles={selectStyle}
                menuPlacement="auto"
                placeholder={placeholder ? placeholder : false}
                isSearchable={true}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isMulti={isMulti}
                formatOptionLabel={formatOptionLabel}
                {...multipleOptionsProps}
                components={{
                  IndicatorSeparator: () => null,
                  NoOptionsMessage,
                }}
              />

              // search user with  react-select
              // <AsyncSelect
              //   cacheOptions
              //   value={value}
              //   loadOptions={loadOptions}
              //   defaultOptions
              //   styles={selectStyle}
              //   menuPlacement="auto"
              //   placeholder={placeholder ? placeholder : false}
              //   isSearchable={isSearchable}
              //   isDisabled={isDisabled}
              //   isClearable={isClearable}
              //   isMulti={isMulti}
              //   formatOptionLabel={formatOptionLabel}
              //   {...multipleOptionsProps}
              //   components={{
              //     IndicatorSeparator: () => null,
              //     NoOptionsMessage,
              //   }}
              // />
            )}
          />
        ) : (
          <ReactSelect
            options={options}
            value={null}
            onChange={(e) => handleChange(e, onChange)}
            styles={selectStyle}
            menuPlacement="auto"
            placeholder={placeholder ? placeholder : false}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            isClearable={isClearable}
            isMulti={isMulti}
            formatOptionLabel={formatOptionLabel}
            {...multipleOptionsProps}
            components={{
              IndicatorSeparator: () => null,
              NoOptionsMessage,
            }}
          />
        )}
      </div>
      {showError && (
        <p className="text-danger font-10 mt-1 me-2">
          {error?.[name]?.message}
        </p>
      )}
    </>
  );
};

export const AsyncSelectField = ({
 options, // This will be ignored in AsyncSelect, used only in standard Select
 name,
 isSearchable,
 placeholder,
 onChange,
 noBorder,
 width,
 returnObjectValue,
 optionLabel,
 optionValue,
 className = "",
 isDisabled,
 isClearable,
 isMulti,
 formatOptionLabel,
 icon,
 iconContent,
 NoOptionsText,
 error,
 control,
 register,
 watch,
 searchParams,
 setSearchParams,
 asyncLoadOptions, // New prop for async function to load options
}: SelectFieldProps) => {

  const [_value, setValue] = useState("");
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{NoOptionsText || "بدون گزینه انتخابی"}</span>
      </components.NoOptionsMessage>
    );
  };

  const LoadingMessage = (props: any) => {
    return (
      <components.LoadingMessage {...props}>
        <span> در حال جستجو...</span>
      </components.LoadingMessage>
    );
  };

  const handleChange = useCallback(
    (val: any, onFieldChange: any) => {
      let returnValue = {
        target: {
          name: name,
          value: isMulti
            ? val
            : returnObjectValue
              ? val
              : optionValue
                ? val?.[optionValue]
                : val?.value,
          type: "select",
        },
      };

      onChange && onChange(returnValue);
      onFieldChange(returnValue);
    },
    [name, onChange, optionValue, returnObjectValue, isMulti]
  );

  const getSelectValue = (value: string) => {
    let _optValue =
      options &&
      options?.find((item: any) =>
        optionValue ? item[optionValue] === value : item.value === value
      );
    if (_optValue === undefined && (value === null || value === ""))
      _optValue = null;
    return _optValue;
  };

  const selectStyle = {
    control: (
      base: any,
      { isFocused, isDisabled }: { isFocused: boolean; isDisabled: boolean }
    ) => ({
      ...base,
      minWidth: width || 140,
      minHeight: 47,
      borderRadius: 12,
      textTransform: "capitalize",
      cursor: "pointer",
      fontWeight: "300",
      fontSize: "13px",
      fontFamily: 'Vazir',
      ...(isDisabled && { backgroundColor: "#f8f9fa" }),
      ...(iconContent && { paddingRight: "40px" }),
      ...(noBorder
        ? { border: "none" }
        : showError
          ? {
            borderColor: "#E53935",
            ":hover": {
              borderColor: "#E53935",
            },
          }
          : {
            borderColor: isFocused ? "#3290FF" : "#E0E0E0",
            ":hover": {
              borderColor: isFocused ? "#3290FF" : null,
            },
          }),
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: "0 3px 0",
      color: "#9292a5",
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 2,
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 8,
      fontFamily: 'Vazir'
    }),
    option: (
      base: any,
      { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }
    ) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#ebf4ff" : null,
        color: isSelected ? "#3290FF" : null,
        fontWeight: isSelected ? "500" : null,
        textAlign: "right",
        borderRadius: 6,
        textTransform: "capitalize",
        cursor: "pointer",
      };
    },
    multiValue: (styles: any) => ({
      ...styles,
      padding: "5px 8px 4px",
      backgroundColor: "#e8eef1",
      borderRadius: "4px",
      fontSize: "14px",
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      borderRadius: "3px",
      transition: "color .2s ease-in",
      ":hover": {
        color: "#F0506D",
      },
    }),
  };

  const multipleOptionsProps = {
    getOptionLabel: (label: any) =>
      optionLabel ? label[optionLabel] : label.label,
    getOptionValue: (value: any) =>
      optionValue ? value[optionValue] : value.value,
  };

  let showError = error?.[name] && error?.[name]?.message;

  return (
    <>
      <div
        className={`inp-box ${className} ${showError ? "invalid-field" : ""}`}
      >
        {iconContent ? (
          iconContent
        ) : (
          <span className="inp-icon">
            <Icon name={icon} />
          </span>
        )}
        <Controller
          control={control}
          name={name}
          defaultValue={null}
          {...register}
          render={({ field: { onChange, value } }) =>
            asyncLoadOptions ? (
              <AsyncSelect
                cacheOptions
                value={getSelectValue(value)}
                loadOptions={asyncLoadOptions}  // Use the async load function here
                defaultOptions={options}
                onChange={(e) => handleChange(e, onChange)}
                styles={selectStyle}
                menuPlacement="auto"
                placeholder={placeholder ? placeholder : false}
                isSearchable={isSearchable}
                isDisabled={isDisabled}
                isClearable={true}
                isMulti={isMulti}
                formatOptionLabel={formatOptionLabel}
                {...multipleOptionsProps}
                components={{
                  IndicatorSeparator: () => null,
                  NoOptionsMessage,
                  LoadingMessage
                }}
              />
            ) : (
              <ReactSelect
                options={options}
                value={getSelectValue(value)}
                onChange={(e) => handleChange(e, onChange)}
                styles={selectStyle}
                menuPlacement="auto"
                placeholder={placeholder ? placeholder : false}
                isSearchable={isSearchable}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isMulti={isMulti}
                formatOptionLabel={formatOptionLabel}
                {...multipleOptionsProps}
                components={{
                  IndicatorSeparator: () => null,
                  NoOptionsMessage,
                }}
              />
            )
          }
        />
      </div>
      {showError && (
        <p className="text-danger font-10 mt-1 me-2">
          {error?.[name]?.message}
        </p>
      )}
    </>
  );
};

export default SelectField;
